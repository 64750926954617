/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import {
  ConnectWallet,
  EnableToken,
  LabeledInlineContentProps,
  Modal,
  ModalProps,
  TabContent,
  Tabs,
  TokenIconWithSymbol,
} from 'components';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'translation';
import { Asset, Vault, Token } from 'types';
import {
  convertTokensToWei,
  formatToReadablePercentage, getContractAddress,
  isAssetEnabled,
  unsafelyGetVToken,
} from 'utilities';

import {
  useGetInviter,
  useGetUserMarketInfo,
  useGetVTokenBalanceOf, useMintUmi,
  useRedeem,
  useRedeemUnderlying, useRepayUmi,
} from 'clients/api';
import useSupply from 'clients/api/mutations/useSupply';
import { MAINNET_PANCAKE_SWAP_TOKENS, PANCAKE_SWAP_TOKENS, TESTNET_PANCAKE_SWAP_TOKENS, TOKENS } from 'constants/tokens';
import { AmountFormProps } from 'containers/AmountForm';
import { AuthContext } from 'context/AuthContext';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

import { useStyles } from '../styles';
import SupplyWithdrawForm from './SupplyWithdrawForm';
import { FormValues } from './types';
import config from '../../../../config';
import { VError } from '../../../../errors';
import { MintVaiUiProps } from '../../../Dashboard/MintRepayVai/MintVai';
import { RepayVaiUiProps } from '../../../Dashboard/MintRepayVai/RepayVai';

const initialFormValues: FormValues = {
  fromToken: PANCAKE_SWAP_TOKENS.bnb,
  fromTokenAmountTokens: '',
  fromTokenAddress: '',
  toToken: config.isOnTestnet ? TESTNET_PANCAKE_SWAP_TOKENS.busd : MAINNET_PANCAKE_SWAP_TOKENS.umi,
  toTokenAddress: '',
  toTokenAmountTokens: '',
  direction: 'exactAmountIn',
};
export interface SupplyWithdrawUiProps {
  className?: string;
  onClose: ModalProps['handleClose'];
  asset: Vault;
  assets: Vault[];
  isXvsEnabled: boolean;
}

export interface SupplyWithdrawProps {
  userTotalBorrowBalanceCents: BigNumber;
  userTotalBorrowLimitCents: BigNumber;
  onSubmitSupply: AmountFormProps['onSubmit'];
  onSubmitWithdraw: AmountFormProps['onSubmit'];
  isSupplyLoading: boolean;
  isWithdrawLoading: boolean;
  setFormValues: (setter: (currentFormValues: FormValues) => FormValues) => void;
  formValues: FormValues;
  days: number;
  setDays: (setter: (arg:number) => number) => void;
}

/**
 * The fade effect on this component results in that it is still rendered after the asset has been set to undefined
 * when closing the modal.
 */
export const SupplyWithdrawUi: React.FC<SupplyWithdrawUiProps & SupplyWithdrawProps> = ({
  className,
  onClose,
  asset,
  assets,
  userTotalBorrowBalanceCents,
  userTotalBorrowLimitCents,
  isXvsEnabled,
  onSubmitSupply,
  onSubmitWithdraw,
  isSupplyLoading,
  isWithdrawLoading,
                                                                                          setFormValues,
                                                                                          formValues,
    days,
    setDays,
}) => {
  const styles = useStyles();

  const { id: assetId, symbol } = asset?.token || {};
  const { t } = useTranslation();

  const vBepTokenContractAddress = unsafelyGetVToken(asset.rewardTokenId.id).address;
  const stakedTokenIdContractAddress = unsafelyGetVToken(asset.stakedTokenId.id).address;
  const vaiUnitrollerContractAddress = getContractAddress('umiVault');
  const tokenInfo: LabeledInlineContentProps[] = asset
    ? [
        {
          label: t('supplyWithdraw.supplyApy'),
          iconSrc: asset.token,
          children: formatToReadablePercentage(asset.supplyApy),
        },
        {
          label: t('supplyWithdraw.distributionApy'),
          iconSrc: TOKENS.cumi,
          children: formatToReadablePercentage(asset.xvsSupplyApy),
        },
      ]
    : [];

  const renderTabContent = ({
    type,
    message,
    title,
    inputLabel,
    enabledButtonKey,
    disabledButtonKey,
    calculateNewBalance,
    isTransactionLoading,
    onSubmit,
  }: {
    type: 'supply' | 'withdraw';
    message: string;
    title: string;
    inputLabel: string;
    enabledButtonKey: string;
    disabledButtonKey: string;
    calculateNewBalance: (initial: BigNumber, amount: BigNumber) => BigNumber;
    isTransactionLoading: boolean;
    onSubmit: AmountFormProps['onSubmit'];
  }) => {
    const maxInput = React.useMemo(() => {
      let maxInputTokens = asset.walletBalance;

      // If asset isn't used as collateral user can withdraw the entire supply
      // balance without affecting their borrow limit
      if (type === 'withdraw' && !asset.collateral) {
        maxInputTokens = asset.supplyApy;
      } else if (type === 'withdraw') {
        // Calculate how much token user can withdraw before they risk getting
        // liquidated (if their borrow balance goes above their borrow limit)

        // Return 0 if borrow limit has already been reached
        if (userTotalBorrowBalanceCents.isGreaterThanOrEqualTo(userTotalBorrowLimitCents)) {
          return new BigNumber(0);
        }

        const marginWithBorrowLimitDollars = userTotalBorrowLimitCents
          .minus(userTotalBorrowBalanceCents)
          .dividedBy(100);

        const collateralAmountPerTokenDollars = asset.dailyEmissionWei.multipliedBy(
          asset.collateralFactor,
        );
        const maxTokensBeforeLiquidation = marginWithBorrowLimitDollars
          .dividedBy(collateralAmountPerTokenDollars)
          .dp(asset.token.decimals, BigNumber.ROUND_DOWN);

        maxInputTokens = BigNumber.minimum(maxTokensBeforeLiquidation, asset.supplyApy);
      }

      return maxInputTokens;
    }, []);
    return (
      <div className={className} css={styles.container}>
        <ConnectWallet message={message}>
          {asset && (
            <EnableToken
              token={asset.rewardTokenId as Token}
              spenderAddress={vaiUnitrollerContractAddress}
              title={title}
            >
              {!asset.stakedTokenId.isNative && (
                  <EnableToken
                    token={asset.stakedTokenId as Token}
                    spenderAddress={vaiUnitrollerContractAddress}
                    title={title}
                    tokenInfo={tokenInfo}
                  >
                    <SupplyWithdrawForm
                      key={`form-${type}`}
                      asset={asset}
                      assets={assets}
                      type={type}
                      tokenInfo={tokenInfo}
                      userTotalBorrowBalanceCents={userTotalBorrowBalanceCents}
                      userTotalBorrowLimitCents={userTotalBorrowLimitCents}
                      onSubmit={onSubmit}
                      inputLabel={inputLabel}
                      enabledButtonKey={enabledButtonKey}
                      disabledButtonKey={disabledButtonKey}
                      maxInput={maxInput}
                      calculateNewBalance={calculateNewBalance}
                      isTransactionLoading={isTransactionLoading}
                      isXvsEnabled={isXvsEnabled}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      days={days}
                      setDays={setDays}
                    />
                  </EnableToken>
              )}
              {asset.stakedTokenId.isNative && (
                  <SupplyWithdrawForm
                    key={`form-${type}`}
                    asset={asset}
                    assets={assets}
                    type={type}
                    tokenInfo={tokenInfo}
                    userTotalBorrowBalanceCents={userTotalBorrowBalanceCents}
                    userTotalBorrowLimitCents={userTotalBorrowLimitCents}
                    onSubmit={onSubmit}
                    inputLabel={inputLabel}
                    enabledButtonKey={enabledButtonKey}
                    disabledButtonKey={disabledButtonKey}
                    maxInput={maxInput}
                    calculateNewBalance={calculateNewBalance}
                    isTransactionLoading={isTransactionLoading}
                    isXvsEnabled={isXvsEnabled}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    days={days}
                    setDays={setDays}
                  />
              )}


            </EnableToken>
          )}
        </ConnectWallet>
      </div>
    );
  };

  const tabsContent: TabContent[] = [
    // {
    //   title: t('supplyWithdraw.withdraw'),
    //   content: renderTabContent({
    //     type: 'withdraw',
    //     message: t('supplyWithdraw.connectWalletToWithdraw'),
    //     title: t('supplyWithdraw.enableToWithdraw', { symbol }),
    //     inputLabel: t('supplyWithdraw.withdrawableAmount'),
    //     enabledButtonKey: t('supplyWithdraw.withdraw'),
    //     disabledButtonKey: t('supplyWithdraw.enterValidAmountWithdraw'),
    //     calculateNewBalance: (initial: BigNumber, amount: BigNumber) => initial.minus(amount),
    //     isTransactionLoading: isWithdrawLoading,
    //     onSubmit: onSubmitWithdraw,
    //   }),
    // },
  ];

  // Prevent user from being able to supply UST or LUNA
  if (isAssetEnabled(assetId)) {
    tabsContent.unshift({
      title: t('supplyWithdraw.supply'),
      content: renderTabContent({
        type: 'supply',
        message: t('supplyWithdraw.connectWalletToSupply'),
        title: t('supplyWithdraw.enableToSupply', { symbol }),
        inputLabel: t('supplyWithdraw.walletBalance'),
        enabledButtonKey: t('supplyWithdraw.supply'),
        disabledButtonKey: t('supplyWithdraw.enterValidAmountSupply'),
        calculateNewBalance: (initial: BigNumber, amount: BigNumber) => initial.plus(amount),
        isTransactionLoading: isSupplyLoading,
        onSubmit: onSubmitSupply,
      }),
    });
  }

  return (
    <Modal
      isOpen={!!assetId}
      handleClose={onClose}
      title={assetId ? <TokenIconWithSymbol token={asset.token} variant="h4" /> : undefined}
    >
      <Tabs tabsContent={tabsContent} />
    </Modal>
  );
};

const SupplyWithdrawModal: React.FC<SupplyWithdrawUiProps> = props => {
  const { asset, assets, isXvsEnabled, onClose, ...rest } = props;
  const { account: { address: accountAddress = '' } = {} } = useContext(AuthContext);
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [days, setDays] = useState(0);
  const { t } = useTranslation();
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();
  const {
    data: { userTotalBorrowBalanceCents, userTotalBorrowLimitCents },
  } = useGetUserMarketInfo({
    accountAddress,
  });

  const { data: vTokenBalanceData } = useGetVTokenBalanceOf(
    { accountAddress, vTokenId: asset.token.id },
    { enabled: !!accountAddress },
  );

  const { mutateAsync: supply, isLoading: isSupplyLoading } = useSupply({
    asset,
    account: accountAddress,
  });

  const { mutateAsync: redeem, isLoading: isRedeemLoading } = useRedeem({
    vTokenId: asset?.token.id,
    accountAddress,
  });

  const { mutateAsync: redeemUnderlying, isLoading: isRedeemUnderlyingLoading } =
    useRedeemUnderlying({
      vTokenId: asset?.token.id,
      accountAddress,
    });
  const { mutateAsync: contractMintVai, isLoading: isMintVaiLoading } = useMintUmi();
  const { mutateAsync: contractRepayVai, isLoading: isRepayVaiLoading } = useRepayUmi();

  const { data: InviterReward } = useGetInviter(
      {
        accountAddress,
      },
      {
        enabled: !!accountAddress,
      },
  );

  const repayVai: RepayVaiUiProps['repayVai'] = async amountWei => {
    if (!accountAddress) {
      // This error should never happen, since the form inside the UI component
      // is disabled if there's no logged in account
      throw new VError({ type: 'unexpected', code: 'undefinedAccountErrorMessage' });
    }
    return contractRepayVai({
      fromAccountAddress: accountAddress,
      amountWei: amountWei.toFixed(),
    });
  };

  const mintUmi: MintVaiUiProps['mintUmi'] = async (amountWei, umiamountWei, tokenA, tokenB) => {
    if (!accountAddress) {
      // This error should never happen, since the form inside the UI component
      // is disabled if there's no logged in account
      throw new VError({ type: 'unexpected', code: 'undefinedAccountErrorMessage' });
    }
    return contractMintVai({
      fromAccountAddress: accountAddress,
      tokenA,
      tokenB,
      InviterReward: InviterReward?.inviter,
      amountWei,
      umiamountWei,
      days,
    });
  };

  const isWithdrawLoading = isRedeemLoading || isRedeemUnderlyingLoading;

  const onSubmitSupply: AmountFormProps['onSubmit'] = async (fromTokenAmount, toTokenAmount) => {
    const fromTokenAddressWei = convertTokensToWei({ value: new BigNumber(fromTokenAmount), token: asset.token });
    const toTokenAddressWei = convertTokensToWei({ value: new BigNumber(toTokenAmount), token: asset.token });
    console.log('With');
    console.log(fromTokenAddressWei.toFixed());
    console.log(toTokenAddressWei.toFixed());
    console.log(formValues.fromTokenAddress);
    console.log(formValues.toTokenAddress);
    const res = await mintUmi(fromTokenAddressWei, toTokenAddressWei, formValues.fromTokenAddress, formValues.toTokenAddress);
    onClose();
    openSuccessfulTransactionModal({
        title: t('supplyWithdraw.successfulSupplyTransactionModal.title'),
        content: t('supplyWithdraw.successfulSupplyTransactionModal.message'),
        amount: {
            valueWei: toTokenAddressWei,
            token: asset.token,
        },
        transactionHash: res ? res.transactionHash : '',
    });
  };

  const onSubmitWithdraw: AmountFormProps['onSubmit'] = async (value) => {
    const amount = new BigNumber(value);
    const amountEqualsSupplyBalance = amount.eq(asset.supplyApy);
    let transactionHash;

    const withdrawAmountWei = convertTokensToWei({
      value: new BigNumber(value),
      token: asset.token,
    });

    const res = await repayVai(withdrawAmountWei);
    onClose();

    openSuccessfulTransactionModal({
      title: t('supplyWithdraw.successfulWithdrawTransactionModal.title'),
      content: t('supplyWithdraw.successfulWithdrawTransactionModal.message'),
      amount: {
        valueWei: convertTokensToWei({
          value: amount,
          token: asset.token,
        }),
        token: asset.token,
      },
      transactionHash: res ? res.transactionHash : '',
    });
  };
  return (
    <SupplyWithdrawUi
      {...rest}
      onClose={onClose}
      asset={asset}
      userTotalBorrowBalanceCents={userTotalBorrowBalanceCents}
      userTotalBorrowLimitCents={userTotalBorrowLimitCents}
      onSubmitSupply={onSubmitSupply}
      onSubmitWithdraw={onSubmitWithdraw}
      isSupplyLoading={isMintVaiLoading}
      isWithdrawLoading={isRepayVaiLoading}
      isXvsEnabled={isXvsEnabled}
      assets={assets}
      formValues={formValues}
      setFormValues={setFormValues}
      days={days}
      setDays={setDays}
    />
  );
};

export default SupplyWithdrawModal;
