/** @jsxImportSource @emotion/react */
import Paper from '@mui/material/Paper';
import BigNumber from 'bignumber.js';
import { Spinner, Tabs } from 'components';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'translation';
import { convertWeiToTokens } from 'utilities';

import {
  useConvertVrt,
  useGetBalanceOf,
  useGetVrtConversionEndTime,
  useGetVrtConversionRatio,
  useGetXvsWithdrawableAmount,
  useWithdrawXvs,
  useRepayUmi, useGetMintedUmi, useGetUmiPrice,
} from 'clients/api';
import { TOKENS } from 'constants/tokens';
import { AuthContext } from 'context/AuthContext';
import { VError } from 'errors/VError';

import Convert, { ConvertProps } from './Convert';
import Withdraw, { WithdrawProps } from './Withdraw';
import { useStyles } from './styles';

export type ConvertVrtUiProps = ConvertProps & WithdrawProps;

export const ConvertVrtUi = ({
  xvsToVrtConversionRatio,
  userVrtBalanceWei,
  convertVrtLoading,
  convertVrt,
  withdrawXvsLoading,
  withdrawXvs,
  xvsWithdrawableAmount,
                               userMinte,
                               pumice,
}: ConvertVrtUiProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const tabsContent = [
    {
      title: t('convertVrt.convert'),
      content: (
        <Convert
          xvsToVrtConversionRatio={xvsToVrtConversionRatio}
          userVrtBalanceWei={userVrtBalanceWei}
          convertVrtLoading={convertVrtLoading}
          convertVrt={convertVrt}
          userMinte={userMinte}
          pumice={pumice}
        />
      ),
    },
    // {
    //   title: t('convertVrt.withdraw'),
    //   content: (
    //     <Withdraw
    //       xvsWithdrawableAmount={xvsWithdrawableAmount}
    //       withdrawXvsLoading={withdrawXvsLoading}
    //       withdrawXvs={withdrawXvs}
    //     />
    //   ),
    // },
  ];

  return (
    <div css={[styles.root]}>
      <Paper css={styles.tabs}>
        <Tabs tabsContent={tabsContent} />
      </Paper>
    </div>
  );
};

const ConvertVrt = () => {
  const { account } = useContext(AuthContext);
  const accountAddress = account?.address;
  const { data: vrtConversionEndTimeData } = useGetVrtConversionEndTime();
  const { data: vrtConversionRatioData } = useGetVrtConversionRatio();
  const { data: userVrtBalanceData } = useGetBalanceOf(
    { accountAddress: accountAddress || '', token: TOKENS.vrt },
    { enabled: !!accountAddress },
  );

  const { data: { totalWithdrawableAmount: xvsWithdrawableAmount } = {} } =
    useGetXvsWithdrawableAmount(
      { accountAddress: accountAddress || '' },
      { enabled: !!accountAddress },
    );

  const { data: userMintedUmiData, isLoading: isGetUserMintedUmiLoading } = useGetMintedUmi(
      {
        accountAddress: account?.address || '',
      },
      {
        enabled: !!account?.address,
      },
  );

  const { data: Price } = useGetUmiPrice(
      { accountAddress: accountAddress || '' },
      { enabled: !!accountAddress },
  );
  const { mutateAsync: convertVrt, isLoading: convertVrtLoading } = useConvertVrt();
  const { mutateAsync: withdrawXvs, isLoading: withdrawXvsLoading } = useWithdrawXvs();
  const { mutateAsync: umiControllerContract, isLoading: isumiControllerContract } = useRepayUmi();

  const handleConvertVrt = async (amount: string) => {
    if (!accountAddress) {
      throw new VError({ type: 'unexpected', code: 'walletNotConnected' });
    }

    return umiControllerContract({
      fromAccountAddress: accountAddress,
      amountWei: amount,
    });
  };

  const handleWithdrawXvs = async () => {
    if (!accountAddress) {
      throw new VError({ type: 'unexpected', code: 'walletNotConnected' });
    }

    return withdrawXvs({
      accountAddress,
    });
  };
  const conversionRatio = useMemo(() => {
    if (vrtConversionRatioData?.conversionRatio) {
      return convertWeiToTokens({
        valueWei: new BigNumber(vrtConversionRatioData.conversionRatio),
        token: TOKENS.cumi,
      });
    }

    return undefined;
  }, [vrtConversionRatioData?.conversionRatio]);
  return (
      <ConvertVrtUi
        xvsToVrtConversionRatio={conversionRatio}
        userVrtBalanceWei={userVrtBalanceData?.balanceWei}
        convertVrtLoading={isumiControllerContract}
        convertVrt={handleConvertVrt}
        withdrawXvs={handleWithdrawXvs}
        withdrawXvsLoading={withdrawXvsLoading}
        xvsWithdrawableAmount={xvsWithdrawableAmount}
        userMinte={userMintedUmiData}
        pumice={Price?.getUmiPrice}
      />
  );

  // @TODO - Handle error state
  return <Spinner />;
};

export default ConvertVrt;
