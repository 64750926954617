/** @jsxImportSource @emotion/react */
import { Paper, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
    Cell,
    CellGroup,
    Delimiter, FormikSubmitButton,
    FormikTextField,
    Icon,
    LinkButton,
    PrimaryButton,
    TextButton, toast,
    TokenIcon,
    Tooltip,
} from 'components';
import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'translation';
import {
    convertWeiToTokens,
    formatToReadablePercentage,
    formatCentsToReadableValue,
    convertTokensToWei,
} from 'utilities';

import {
    useGetCurrentVotes,
    useGetVoteDelegateAddress,
    useSetInviter,
    useSetVoteDelegate,
    useSetOutRoi,
    useSetOrganization,
    useSetRoi,
    useSetSyndicate,
    useGetOrganization,
    useGetOutroi,
    useGetRoi,
    useGetSndicateGloblAmount,
    useGetStakePool,
    useGetRewardsPool,
    useGetCumiPool,
    useGetUserNumber,
    GetUserNumberOutput,
    useSyndicateRateL1,
    useSyndicateRateL2,
    useSetSyndicateRateL1,
    useSetSyndicateRateL2,
} from 'clients/api';
import PATHS from 'constants/path';
import { TOKENS } from 'constants/tokens';
import { AuthContext } from 'context/AuthContext';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';

import { Form, Formik, FormikHelpers } from 'formik';
import useIsMounted from 'hooks/useIsMounted';
import DelegateModal from '../DelegateModal';
import { useStyles } from './styles';
import TEST_IDS from '../testIds';

interface VotingWalletUiProps {
  votingWeightWei: BigNumber;
  openAuthModal: () => void;
  userStakedWei: BigNumber;
  connectedWallet: boolean;
  currentUserAccountAddress: string | undefined;
  delegate: string | undefined;
  setVoteDelegation: (address: string) => void;
  isVoteDelegationLoading: boolean;
  delegateModelIsOpen: boolean;
  setDelegateModelIsOpen: (open: boolean) => void;
    usernumber?: GetUserNumberOutput;
}

export const VotingWalletUi: React.FC<VotingWalletUiProps> = ({
  votingWeightWei,
  userStakedWei,
  connectedWallet,
  openAuthModal,
  currentUserAccountAddress,
  delegate,
  setVoteDelegation,
  isVoteDelegationLoading,
  delegateModelIsOpen,
  setDelegateModelIsOpen,
                                                                  usernumber,
}) => {
  const { t, Trans } = useTranslation();
  const styles = useStyles();
    const { account } = useContext(AuthContext);
    const accountAddress = account?.address || '';
    const isMounted = useIsMounted();
    const { data: Organization } = useGetOrganization(
        {
            accountAddress: account?.address || '',
        },
        {
            enabled: !!account?.address,
        },
    );

    const { data: Outroi } = useGetOutroi(
        {
            accountAddress: account?.address || '',
        },
        {
            enabled: !!account?.address,
        },
    );

    const { data: Roi } = useGetRoi(
        {
            accountAddress: account?.address || '',
        },
        {
            enabled: !!account?.address,
        },
    );
    const { data: SyndicateRateL1 } = useSyndicateRateL1(
        {
            accountAddress: account?.address || '',
        },
        {
            enabled: !!account?.address,
        },
    );

    const { data: SyndicateRateL2 } = useSyndicateRateL2(
        {
            accountAddress: account?.address || '',
        },
        {
            enabled: !!account?.address,
        },
    );

    const { mutateAsync: SetOutRoi, isLoading: isSetOutRoi } = useSetOutRoi(
        {
            onSuccess: data => {
                setDelegateModelIsOpen(false);
            },
        },
    );


    const { mutateAsync: SetOrganization, isLoading: isSetOrganization } = useSetOrganization(
        {
            onSuccess: data => {
                setDelegateModelIsOpen(false);
            },
        },
    );


    const { mutateAsync: SetRoi, isLoading: isSetRoi } = useSetRoi(
        {
            onSuccess: data => {
                setDelegateModelIsOpen(false);
            },
        },
    );


    const { mutateAsync: SetSyndicate, isLoading: isSetSyndicate } = useSetSyndicate(
        {
            onSuccess: data => {
                setDelegateModelIsOpen(false);
            },
        },
    );

    const { mutateAsync: SetSyndicateRateL1, isLoading: isSetSyndicateRateL1 } = useSetSyndicateRateL1(
        {
            onSuccess: data => {
                setDelegateModelIsOpen(false);
            },
        },
    );

    const { mutateAsync: SetSyndicateRateL2, isLoading: isSetSyndicateRateL2 } = useSetSyndicateRateL2(
        {
            onSuccess: data => {
                setDelegateModelIsOpen(false);
            },
        },
    );


    const onSubmitL1 = async (values: any, { resetForm }: FormikHelpers<any>) => {
        try {
            const { amountWei } = values;
            console.log(amountWei);
            const fromAccountAddress = accountAddress;
            console.log(fromAccountAddress);
            await SetSyndicateRateL1({ fromAccountAddress, amountWei });
            if (isMounted()) {
                resetForm();
            }
        } catch (error) {
            const { message } = error as Error;
            toast.error({
                message,
            });
        }
    };

    const onSubmitL2 = async (values: any, { resetForm }: FormikHelpers<any>) => {
        try {
            const { amountWei } = values;
            console.log(amountWei);
            const fromAccountAddress = accountAddress;
            console.log(fromAccountAddress);
            await SetSyndicateRateL2({ fromAccountAddress, amountWei });
            if (isMounted()) {
                resetForm();
            }
        } catch (error) {
            const { message } = error as Error;
            toast.error({
                message,
            });
        }
    };

    const onSubmitOutRoi = async (values: any, { resetForm }: FormikHelpers<any>) => {
        try {
            const { amountWei } = values;
            console.log(amountWei);
            const { fromAccountAddress: { accountAddress: fromAccountAddress } } = values;
            console.log(fromAccountAddress);
            await SetOutRoi({ fromAccountAddress, amountWei });
            if (isMounted()) {
                resetForm();
            }
        } catch (error) {
            const { message } = error as Error;
            toast.error({
                message,
            });
        }
    };
    const onSubmitOrganization = async (values: any, { resetForm }: FormikHelpers<any>) => {
        try {
            const amountWei = convertTokensToWei({
                value: new BigNumber(values.amountWei),
                token: TOKENS.umi,
            }).toFixed();

            const { accountAddress: { accountAddress: fromAccountAddress } } = values;
            console.log(fromAccountAddress);
            await SetOrganization({ fromAccountAddress, amountWei });
            if (isMounted()) {
                resetForm();
            }
        } catch (error) {
            const { message } = error as Error;
            toast.error({
                message,
            });
        }
    };
    const getOrganizationWei = Organization ? new BigNumber(Organization.inviter).dividedBy(new BigNumber(10).pow(18)) : new BigNumber(0);
    const onSubmitRoi = async (values: any, { resetForm }: FormikHelpers<any>) => {
        try {
            const { amountWei } = values;
            console.log(amountWei);
            const { accountAddress: { accountAddress: fromAccountAddress } } = values;
            console.log(fromAccountAddress);
            await SetRoi({ fromAccountAddress, amountWei });
            if (isMounted()) {
                resetForm();
            }
        } catch (error) {
            const { message } = error as Error;
            toast.error({
                message,
            });
        }
    };
    const onSubmitSyndicate = async (values: any, { resetForm }: FormikHelpers<any>) => {
        try {
            const toAccountAddress = values.address;
            const { flat } = values;
            const fromAccountAddress = accountAddress;
            await SetSyndicate({ fromAccountAddress, toAccountAddress, flat });
            if (isMounted()) {
                resetForm();
            }
        } catch (error) {
            const { message } = error as Error;
            toast.error({
                message,
            });
        }
    };

    const { data: SndicateGloblAmount } = useGetSndicateGloblAmount(
        { accountAddress: accountAddress || '' },
        { enabled: !!accountAddress },
    );
    const { data: StakePool } = useGetStakePool(
        { accountAddress: accountAddress || '' },
        { enabled: !!accountAddress },
    );
    const { data: RewardsPool } = useGetRewardsPool(
        { accountAddress: accountAddress || '' },
        { enabled: !!accountAddress },
    );
    const { data: CumiPool } = useGetCumiPool(
        { accountAddress: accountAddress || '' },
        { enabled: !!accountAddress },
    );

  const previouslyDelegated = !!delegate;
  const userHasLockedXVS = userStakedWei.isGreaterThan(0);
    const Stake = StakePool ? new BigNumber(StakePool.Amount) : new BigNumber(0);
    const Sndicate = SndicateGloblAmount ? new BigNumber(SndicateGloblAmount.Amount) : new BigNumber(0);
    const Rewards = RewardsPool ? new BigNumber(RewardsPool.inviter) : new BigNumber(0);
    const Cumi = CumiPool ? new BigNumber(CumiPool.Amount) : new BigNumber(0);
    const cells: Cell[] = [
        {
            label: t('account.vote.netApy'),
            value: formatCentsToReadableValue({ value: Stake }),
            tooltip: t('account.vote.netApyTooltip'),
        },
        {
            label: t('syndicate.AvailableAirdrop'),
            value: formatCentsToReadableValue({ value: Sndicate }),
        },
        {
            label: t('syndicate.AirdropsSent'),
            value: formatCentsToReadableValue({ value: Rewards }),
        },
        {
            label: t('syndicate.AirdropsReceived'),
            value: formatCentsToReadableValue({ value: Cumi }),
        },
        {
            label: t('syndicate.SyndicateMembers'),
            value: formatCentsToReadableValue({ value: usernumber?.syndicatecount }),
        },
    ];
  return (
      <div css={styles.section}>
          {/* <div css={styles.sectionTitle}> */}
          {/*     <Typography variant="h3" css={styles.sectionTitleText}> */}
          {/*         {t('account.accountSummary.title')} */}
          {/*     </Typography> */}
          {/* </div> */}

          <CellGroup cells={cells} data-testid={TEST_IDS.stats} />

          <Formik
            initialValues={{
                  address: '',
                flat: 0,
                accountAddress: { accountAddress },
            }}
            onSubmit={onSubmitSyndicate}
            // validationSchema={addressValidationSchema}
            isInitialValid={false}
            validateOnMount
            validateOnChange
          >
              {({ setFieldValue }) => (
                  <Form>
                      <div css={styles.inputLabels} />
                      <FormikTextField
                        placeholder="設置聯合節點"
                        name="address"
                        maxLength={42}
                      />
                      <FormikTextField
                        placeholder="设置公会节点，1公会，2节点"
                        name="flat"
                        maxLength={42}
                      />
                      <FormikSubmitButton
                        fullWidth
                        enabledLabel="設置聯合節點"
                        css={styles.submitButton}
                        loading={isSetSyndicate}
                      />
                  </Form>
              )}
          </Formik>

          <Formik
            initialValues={{
                  amountWei: '',
                accountAddress: { accountAddress },
            }}
            onSubmit={onSubmitOrganization}
            // validationSchema={addressValidationSchema}
            isInitialValid={false}
            validateOnMount
            validateOnChange
          >
              {({ setFieldValue }) => (
                  <Form>
                      <div css={styles.inputLabels}>
                          <Typography color="textPrimary" fontSize={12}>联创分红: { getOrganizationWei.toFixed() }</Typography>
                      </div>
                      <FormikTextField
                        placeholder="設置聯合節點費用"
                        name="amountWei"
                        maxLength={42}
                      />
                      <FormikSubmitButton
                        fullWidth
                        enabledLabel="設置聯合節點費用"
                        css={styles.submitButton}
                        loading={isSetOrganization}
                      />
                  </Form>
              )}
          </Formik>

          <Formik
            initialValues={{
                amountWei: '',
                accountAddress: { accountAddress },
              }}
            onSubmit={onSubmitRoi}
            // validationSchema={addressValidationSchema}
            isInitialValid={false}
            validateOnMount
            validateOnChange
          >
              {({ setFieldValue }) => (
                  <Form>
                      <div css={styles.inputLabels}>
                          <Typography color="textPrimary" fontSize={12}>入金CUMI: {Roi?.inviter}</Typography>
                      </div>
                      <FormikTextField
                        placeholder="設置入金CUMI費率"
                        name="amountWei"
                        maxLength={42}
                      />
                      <FormikSubmitButton
                        fullWidth
                        enabledLabel="設置入金CUMI費率"
                        css={styles.submitButton}
                        loading={isSetRoi}
                      />
                  </Form>
              )}
          </Formik>

          <Formik
            initialValues={{
                amountWei: '',
                fromAccountAddress: { accountAddress },
              }}
            onSubmit={onSubmitOutRoi}
            // validationSchema={addressValidationSchema}
            isInitialValid={false}
            validateOnMount
            validateOnChange
          >
              {({ setFieldValue }) => (
                  <Form>
                      <div css={styles.inputLabels}>
                          <Typography color="textPrimary" fontSize={12}>出金轉換UMI費率: {Outroi?.inviter}</Typography>
                      </div>
                      <FormikTextField
                        placeholder="設置轉換UMI費率"
                        name="amountWei"
                        maxLength={42}
                      />
                      <FormikSubmitButton
                        fullWidth
                        enabledLabel="設置轉換UMI費率"
                        css={styles.submitButton}
                        loading={isSetOutRoi}
                      />
                  </Form>
              )}
          </Formik>

          <Formik
            initialValues={{
                  amountWei: '',
                  fromAccountAddress: { currentUserAccountAddress },
              }}
            onSubmit={onSubmitL2}
              // validationSchema={addressValidationSchema}
            isInitialValid={false}
            validateOnMount
            validateOnChange
          >
              {({ setFieldValue }) => (
                  <Form>
                      <div css={styles.inputLabels}>
                          <Typography color="textPrimary" fontSize={12}>節點費率: {SyndicateRateL2?.inviter}</Typography>
                      </div>
                      <FormikTextField
                        placeholder="設置節點費率"
                        name="amountWei"
                        maxLength={42}
                      />
                      <FormikSubmitButton
                        fullWidth
                        enabledLabel="設置節點費率"
                        css={styles.submitButton}
                        loading={isSetSyndicateRateL2}
                      />
                  </Form>
              )}
          </Formik>

          <Formik
            initialValues={{
                  amountWei: '',
                  fromAccountAddress: { currentUserAccountAddress },
              }}
            onSubmit={onSubmitL1}
              // validationSchema={addressValidationSchema}
            isInitialValid={false}
            validateOnMount
            validateOnChange
          >
              {({ setFieldValue }) => (
                  <Form>
                      <div css={styles.inputLabels}>
                          <Typography color="textPrimary" fontSize={12}>公會費率: {SyndicateRateL1?.req}</Typography>
                      </div>
                      <FormikTextField
                        placeholder="設置公會費率"
                        name="amountWei"
                        maxLength={42}
                      />
                      <FormikSubmitButton
                        fullWidth
                        enabledLabel="設置公會費率"
                        css={styles.submitButton}
                        loading={isSetSyndicateRateL1}
                      />
                  </Form>
              )}
          </Formik>
      </div>
    // <div css={styles.root}>
    //
    //   {/* <Typography variant="h4">{t('vote.votingWallet')}</Typography> */}
    //   {/* <Paper css={styles.votingWalletPaper}> */}
    //   {/*   <div css={styles.votingWeightContainer}> */}
    //   {/*     <Typography variant="body2" css={styles.subtitle}> */}
    //   {/*       {t('vote.votingWeight')} */}
    //   {/*     </Typography> */}
    //
    //   {/*     <Typography variant="h3" css={styles.value} data-testid={TEST_IDS.votingWeightValue}> */}
    //   {/*       {readableVoteWeight} */}
    //   {/*     </Typography> */}
    //   {/*   </div> */}
    //
    //   {/*   <Delimiter css={styles.delimiter} /> */}
    //
    //   {/*   <div css={styles.totalLockedContainer}> */}
    //   {/*     <div css={styles.totalLockedTitle}> */}
    //   {/*       <Typography variant="body2" css={[styles.subtitle, styles.totalLockedText]}> */}
    //   {/*         {t('vote.totalLocked')} */}
    //   {/*       </Typography> */}
    //
    //   {/*       {previouslyDelegated && ( */}
    //   {/*         <Tooltip */}
    //   {/*           title={t('vote.youDelegatedTo', { delegate })} */}
    //   {/*           css={[styles.infoIcon, styles.subtitle]} */}
    //   {/*         > */}
    //   {/*           <Icon name="info" /> */}
    //   {/*         </Tooltip> */}
    //   {/*       )} */}
    //   {/*     </div> */}
    //
    //   {/*     <div css={styles.totalLockedValue}> */}
    //   {/*       <TokenIcon token={TOKENS.xvs} css={styles.tokenIcon} /> */}
    //
    //   {/*       <Typography variant="h3" css={styles.value} data-testid={TEST_IDS.totalLockedValue}> */}
    //   {/*         {readableXvsLocked} */}
    //   {/*       </Typography> */}
    //   {/*     </div> */}
    //   {/*   </div> */}
    //
    //   {/*   {!connectedWallet && ( */}
    //   {/*     <PrimaryButton css={styles.actionButton} onClick={openAuthModal}> */}
    //   {/*       {t('connectWallet.connectButton')} */}
    //   {/*     </PrimaryButton> */}
    //   {/*   )} */}
    //
    //   {/*   {connectedWallet && !userHasLockedXVS && ( */}
    //   {/*     <LinkButton css={styles.actionButton} to={PATHS.VAULTS}> */}
    //   {/*       {t('vote.depositXvs')} */}
    //   {/*     </LinkButton> */}
    //   {/*   )} */}
    //
    //   {/*   {connectedWallet && userHasLockedXVS && ( */}
    //   {/*     <PrimaryButton css={styles.actionButton} onClick={() => setDelegateModelIsOpen(true)}> */}
    //   {/*       {previouslyDelegated ? t('vote.redelegate') : t('vote.delegate')} */}
    //   {/*     </PrimaryButton> */}
    //   {/*   )} */}
    //   {/* </Paper> */}
    //
    //   {/* <Paper css={[styles.votingWalletPaper, styles.voteSection]}> */}
    //   {/*   <Typography variant="body2" color="textPrimary" css={styles.toVote}> */}
    //   {/*     {t('vote.toVoteYouShould')} */}
    //   {/*   </Typography> */}
    //
    //   {/*   <Typography variant="small2" color="textPrimary" css={styles.depositTokens}> */}
    //   {/*     <Trans */}
    //   {/*       i18nKey="vote.depositYourTokens" */}
    //   {/*       components={{ */}
    //   {/*         Link: ( */}
    //   {/*           <Link */}
    //   {/*             to={PATHS.VAULTS} */}
    //   {/*             css={styles.clickableText} */}
    //   {/*             data-testid={TEST_IDS.depositYourTokens} */}
    //   {/*           /> */}
    //   {/*         ), */}
    //   {/*       }} */}
    //   {/*     /> */}
    //   {/*   </Typography> */}
    //
    //   {/*   <Typography variant="small2" color="textPrimary"> */}
    //   {/*     <Trans */}
    //   {/*       i18nKey="vote.delegateYourVoting" */}
    //   {/*       components={{ */}
    //   {/*         Anchor: ( */}
    //   {/*           <span */}
    //   {/*             css={styles.clickableText} */}
    //   {/*             role="button" */}
    //   {/*             aria-pressed="false" */}
    //   {/*             tabIndex={0} */}
    //   {/*             onClick={() => setDelegateModelIsOpen(true)} */}
    //   {/*             data-testid={TEST_IDS.delegateYourVoting} */}
    //   {/*           /> */}
    //   {/*         ), */}
    //   {/*       }} */}
    //   {/*     /> */}
    //   {/*   </Typography> */}
    //   {/* </Paper> */}
    //
    //   {/* <DelegateModal */}
    //   {/*   onClose={() => setDelegateModelIsOpen(false)} */}
    //   {/*   isOpen={delegateModelIsOpen} */}
    //   {/*   currentUserAccountAddress={currentUserAccountAddress} */}
    //   {/*   previouslyDelegated={previouslyDelegated} */}
    //   {/*   setVoteDelegation={setVoteDelegation} */}
    //   {/*   isVoteDelegationLoading={isVoteDelegationLoading} */}
    //   {/*   openAuthModal={openAuthModal} */}
    //   {/* /> */}
    // </div>
  );
};

const VotingWallet: React.FC = () => {
  const [delegateModelIsOpen, setDelegateModelIsOpen] = useState(false);
  const { t } = useTranslation();
  const { account: { address: accountAddress } = { address: undefined }, openAuthModal } =
    useContext(AuthContext);

  const { data: currentVotesData } = useGetCurrentVotes(
    { accountAddress: accountAddress || '' },
    { enabled: !!accountAddress },
  );
  const { data: delegateData } = useGetVoteDelegateAddress(
    { accountAddress: accountAddress || '' },
    { enabled: !!accountAddress },
  );
  const { data: number } = useGetUserNumber();

  // const [xvsVault] = vaults.filter(v => v.stakedTokenId.id === TOKENS.xvs.id);
  const userStakedWei = new BigNumber(0);

  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();
  const { mutateAsync: setVoteDelegation, isLoading: isVoteDelegationLoading } = useSetVoteDelegate(
    {
      onSuccess: data => {
        setDelegateModelIsOpen(false);
        // openSuccessfulTransactionModal({
        //     title: t('vote.successfulDelegationModal.title'),
        //     content: t('vote.successfulDelegationModal.message'),
        //     amount: {
        //         valueWei: userStakedWei,
        //         token: TOKENS.xvs,
        //     },
        //     transactionHash: data.transactionHash,
        // });
      },
    },
  );

  return (
    <VotingWalletUi
      connectedWallet={!!accountAddress}
      openAuthModal={openAuthModal}
      currentUserAccountAddress={accountAddress}
      votingWeightWei={currentVotesData?.votesWei || new BigNumber(0)}
      userStakedWei={userStakedWei}
      delegate={delegateData?.delegateAddress}
      usernumber={number}
      setVoteDelegation={(delegateAddress: string) =>
        setVoteDelegation({ delegateAddress, accountAddress: accountAddress || '' })
      }
      isVoteDelegationLoading={isVoteDelegationLoading}
      delegateModelIsOpen={delegateModelIsOpen}
      setDelegateModelIsOpen={setDelegateModelIsOpen}
    />
  );
};

export default VotingWallet;
