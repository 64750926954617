/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import {
  ConnectWallet,
  EnableToken,
  FormikSubmitButton,
  FormikTokenTextField,
  LabeledInlineContent,
  Spinner,
} from 'components';
import { VError } from 'errors';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';
import { convertTokensToWei, convertWeiToTokens, getContractAddress } from 'utilities';
import type { TransactionReceipt } from 'web3-core';

import { useGetBalanceOf, useGetMintedVai, useRepayVai, useRepayUmi, useGetMintedUmi, GetMintedUmiOutput, useRepayAirdrop } from 'clients/api';
import { TOKENS } from 'constants/tokens';
import { AmountForm, AmountFormProps } from 'containers/AmountForms';
import { AuthContext } from 'context/AuthContext';
import { DisableLunaUstWarningContext } from 'context/DisableLunaUstWarning';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import { Typography } from '@mui/material';
import { useStyles } from '../styles';
import RepayFee from './RepayFee';
import TEST_IDS from './testIds';

const vaiControllerContractAddress = getContractAddress('umiVault');

export interface RepayVaiUiProps {
  disabled: boolean;
  isRepayVaiLoading: boolean;
  repayVai: (amountWei: BigNumber) => Promise<TransactionReceipt | undefined>;
  isInitialLoading: boolean;
  userBalanceWei?: BigNumber;
  userMinte?: GetMintedUmiOutput;
}

export const RepayVaiUi: React.FC<RepayVaiUiProps> = ({
  disabled,
  userBalanceWei,
                                                        userMinte,
  isRepayVaiLoading,
  isInitialLoading,
  repayVai,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const handleTransactionMutation = useHandleTransactionMutation();
  const userMintedWei = userMinte ? userMinte?.balance : 0;
  const limitTokens = React.useMemo(() => {
    const limitWei =
      new BigNumber(userMintedWei) || new BigNumber(0);

    return convertWeiToTokens({ valueWei: limitWei, token: TOKENS.vai }).toFixed();
  }, [userBalanceWei?.toFixed(), userMintedWei]);

  // Convert minted wei into VAI
  const readableRepayableVai = useConvertWeiToReadableTokenString({
    valueWei: new BigNumber(userMintedWei),
    token: TOKENS.cumi,
  });

  const hasRepayableVai = userMintedWei || false;
  const dataLimit = React.useMemo(() => {
    let date;
    if (userMinte && userMinte.stake > '0'){
      if (userMinte.ClaimedDates === '0'){
        date = (Math.ceil(Date.now() / 1000) - parseInt(userMinte.firstClaimedDates, 10)) / 86400;
      } else {
        date = (Math.ceil(Date.now() / 1000) - parseInt(userMinte.ClaimedDates, 10)) / 86400;
      }
      return parseInt(date.toString(), 10);
    }
    return 0;
  }, [userMinte]);
  const dateLimit = userMinte ? new Date(parseInt(userMinte.endClaimedDates, 10) * 1000) : 0;
  console.log(dateLimit);
  const arp = React.useMemo(() => userMinte ? new BigNumber(userMinte.arp).dividedBy(new BigNumber(10).pow(18)) : new BigNumber(0), [userMinte]);
  const onSubmit: AmountFormProps['onSubmit'] = async amountTokens => {
    const amountWei = convertTokensToWei({
      value: new BigNumber(amountTokens),
      token: TOKENS.cumi,
    });

    return handleTransactionMutation({
      mutate: () => repayVai(amountWei),
      successTransactionModalProps: transactionReceipt => ({
        title: t('mintRepayVai.repayVai.successfulTransactionModal.title'),
        content: t('mintRepayVai.repayVai.successfulTransactionModal.message'),
        amount: {
          valueWei: amountWei,
          token: TOKENS.cumi,
        },
        transactionHash: transactionReceipt.transactionHash,
      }),
    });
  };

  return (
    <ConnectWallet message={t('mintRepayVai.repayVai.connectWallet')}>
      <EnableToken
        title={t('mintRepayVai.repayVai.enableToken')}
        token={TOKENS.cumi}
        spenderAddress={vaiControllerContractAddress}
      >
        {isInitialLoading ? (
          <Spinner />
        ) : (
          <AmountForm onSubmit={onSubmit} css={styles.tabContentContainer}>
            {({ values }) => (
              <>
                <div css={styles.ctaContainer}>
                  <section css={styles.title}>
                    <Typography variant="h3">{ arp.multipliedBy(new BigNumber(dataLimit)).toFixed()}</Typography>
                    <Typography variant="small2">{t('convertVrt.withdrawableAmount')}</Typography>
                  </section>
                  {/* <FormikTokenTextField */}
                  {/*   name="amount" */}
                  {/*   css={styles.textField} */}
                  {/*   token={TOKENS.cumi} */}
                  {/*   max={limitTokens} */}
                  {/*   disabled={disabled || isRepayVaiLoading} */}
                  {/*   rightMaxButton={{ */}
                  {/*     label: t('mintRepayVai.repayVai.rightMaxButtonLabel'), */}
                  {/*     valueOnClick: limitTokens, */}
                  {/*   }} */}
                  {/*   data-testid={TEST_IDS.repayTextField} */}
                  {/* /> */}
                  <LabeledInlineContent
                    css={styles.getRow({ isLast: false })}
                    iconSrc={TOKENS.cumi}
                    label={t('mintRepayVai.repayVai.repayAipdropDay')}
                  >
                    {dataLimit}
                  </LabeledInlineContent>

                  <LabeledInlineContent
                    css={styles.getRow({ isLast: false })}
                    iconSrc={TOKENS.cumi}
                    label={t('mintRepayVai.repayVai.repayVaiBalance')}
                  >
                    {readableRepayableVai}
                  </LabeledInlineContent>


                  <LabeledInlineContent
                    css={styles.getRow({ isLast: false })}
                    iconSrc={TOKENS.cumi}
                    label={t('mintRepayVai.repayVai.repayAipdropend')}
                  >
                    --
                  </LabeledInlineContent>

                  {/* <RepayFee repayAmountTokens={values.amount} /> */}
                </div>

                <FormikSubmitButton
                  variant="secondary"
                  // loading={isRepayVaiLoading}
                  disabled={disabled}
                  enabledLabel={t('mintRepayVai.repayVai.btnRepayVai')}
                  fullWidth
                />
              </>
            )}
          </AmountForm>
        )}
      </EnableToken>
    </ConnectWallet>
  );
};

const RepayVai: React.FC = () => {
  const { account } = useContext(AuthContext);

  const { hasLunaOrUstCollateralEnabled, openLunaUstWarningModal } = useContext(
    DisableLunaUstWarningContext,
  );

  const { data: userVaiBalanceData, isLoading: isGetUserVaiBalanceWeiLoading } = useGetBalanceOf(
    {
      accountAddress: account?.address || '',
      token: TOKENS.cumi,
    },
    {
      enabled: !!account?.address,
    },
  );

  const { data: userMintedUmiData, isLoading: isGetUserMintedUmiLoading } = useGetMintedUmi(
    {
      accountAddress: account?.address || '',
    },
    {
      enabled: !!account?.address,
    },
  );

  const isInitialLoading = isGetUserMintedUmiLoading || isGetUserVaiBalanceWeiLoading;

  // const { mutateAsync: umiControllerContract, isLoading: isumiControllerContract } = useRepayUmi();
  const { mutateAsync: umiControllerContract, isLoading: isumiControllerContract } = useRepayAirdrop();

  const repayVai: RepayVaiUiProps['repayVai'] = async amountWei => {
    if (!account) {
      // This error should never happen, since the form inside the UI component
      // is disabled if there's no logged in account
      throw new VError({ type: 'unexpected', code: 'undefinedAccountErrorMessage' });
    }

    // Block action is user has LUNA or UST enabled as collateral
    if (hasLunaOrUstCollateralEnabled) {
      openLunaUstWarningModal();
      return;
    }

    return umiControllerContract({
      fromAccountAddress: account.address,
    });
  };

  return (
    <RepayVaiUi
      disabled={!account}
      isInitialLoading={isInitialLoading}
      userBalanceWei={userVaiBalanceData?.balanceWei}
      userMinte={userMintedUmiData}
      isRepayVaiLoading={isumiControllerContract}
      repayVai={repayVai}
    />
  );
};

export default RepayVai;
