/** @jsxImportSource @emotion/react */
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Icon } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'translation';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/modules/pagination/pagination.min.css';
import top1 from 'assets/img/TOP-img-cn01.png';
import top2 from 'assets/img/TOP-img-cn02.png';
import top3 from 'assets/img/TOP-img-cn03.png';
import cn03B from 'assets/img/TOP-img-cn03B.png';
import top4 from 'assets/img/TOP-img-cn04.png';
import top5 from 'assets/img/TOP-img-cn05.png';
import top6 from 'assets/img/TOP-img-cn06.png';


import { LS_KEY_HIDE_HIGHER_RISK_TOKENS_NOTICE } from 'constants/localStorageKeys';

import { useStyles } from './styles';

export interface HigherRiskTokensNoticeUiProps {
  isVisible: boolean;
  onHide: () => void;
}

export const HigherRiskTokensNoticeUi: React.FC<HigherRiskTokensNoticeUiProps> = ({
  onHide,
  isVisible,
  ...containerProps
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  if (!isVisible) {
    return null;
  }

  return (
    <Paper css={styles.container} {...containerProps}>
      <div css={styles.content}>
        <Swiper
          pagination={{
          dynamicBullets: true,
        }}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          spaceBetween={30}
        >
          <SwiperSlide><img src={top1} alt="Logo" css={styles.mySwiper} /></SwiperSlide>
          <SwiperSlide><img src={top2} alt="Logo" css={styles.mySwiper} /></SwiperSlide>
          <SwiperSlide><img src={top3} alt="Logo" css={styles.mySwiper} /></SwiperSlide>
          <SwiperSlide><img src={cn03B} alt="Logo" css={styles.mySwiper} /></SwiperSlide>
          <SwiperSlide><img src={top4} alt="Logo" css={styles.mySwiper} /></SwiperSlide>
          <SwiperSlide><img src={top5} alt="Logo" css={styles.mySwiper} /></SwiperSlide>
          <SwiperSlide><img src={top6} alt="Logo" css={styles.mySwiper} /></SwiperSlide>
        </Swiper>

        {/* <Icon name="dashboard" css={styles.icon} /> */}

        {/* <div css={styles.textContainer}> */}
        {/*   <Typography variant="h4" css={styles.title}> */}
        {/*     {t('dashboard.higherRiskTokensNotice.title')} */}
        {/*   </Typography> */}

        {/*   <Typography>{t('dashboard.higherRiskTokensNotice.description')}</Typography> */}
        {/* </div> */}
      </div>

      {/* <button css={styles.closeButton} onClick={onHide} type="button"> */}
      {/*   <Icon name="close" /> */}
      {/* </button> */}
    </Paper>
  );
};

const HigherRiskTokensNotice: React.FC = () => {
  const [isVisible, setIsVisible] = useState(
    !window.localStorage.getItem(LS_KEY_HIDE_HIGHER_RISK_TOKENS_NOTICE),
  );

  const handleHide = () => {
    setIsVisible(false);
    window.localStorage.setItem(LS_KEY_HIDE_HIGHER_RISK_TOKENS_NOTICE, 'true');
  };

  return <HigherRiskTokensNoticeUi isVisible onHide={handleHide} />;
};

export default HigherRiskTokensNotice;
