/** @jsxImportSource @emotion/react */
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { BorrowLimitUsedAccountHealth, Icon, Toggle, ToggleProps, Tooltip } from 'components';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';
import { formatCentsToReadableValue, formatToReadablePercentage } from 'utilities';

import {
  useGetUmiPrice, useGetSndicateGloblAmount, useGetStakePool, GetUserNumberOutput, useGetMintedUmi,
} from 'clients/api';
import { AuthContext } from 'context/AuthContext';
import BigNumber from 'bignumber.js';
import { useMyAccountStyles as useStyles } from './styles';
import { crown, purple_crown } from '../../../components/Icon/icons';

export interface MyAccountUiProps {
  netApyPercentage: number | undefined;
  dailyEarningsCents: number | undefined;
  supplyBalanceCents: number | undefined;
  borrowBalanceCents: number | undefined;
  borrowLimitCents: number | undefined;
  safeBorrowLimitPercentage: number;
  isXvsEnabled: boolean;
  usernumber?: GetUserNumberOutput;
  onXvsToggle: (newValue: boolean) => void;
  className?: string;
}

export const MyAccountUi = ({
  netApyPercentage,
  dailyEarningsCents,
  supplyBalanceCents,
  borrowBalanceCents,
  borrowLimitCents,
  safeBorrowLimitPercentage,
  isXvsEnabled,
  onXvsToggle,
  className,
                              usernumber,
}: MyAccountUiProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { account: { address: accountAddress } = { address: undefined }, openAuthModal } =
      useContext(AuthContext);
  const handleXvsToggleChange: ToggleProps['onChange'] = (_event, checked) => onXvsToggle(checked);

  const safeBorrowLimitCents =
    typeof borrowLimitCents === 'number'
      ? Math.floor((borrowLimitCents * safeBorrowLimitPercentage) / 100)
      : undefined;

  const readableSafeBorrowLimit = formatCentsToReadableValue({
    value: safeBorrowLimitCents,
  });

  const readableNetApyPercentage = formatToReadablePercentage(netApyPercentage);

  const readableBorrowBalance = formatCentsToReadableValue({
    value: borrowBalanceCents,
  });

  const readableSupplyBalance = formatCentsToReadableValue({
    value: supplyBalanceCents,
  });

  const { data: Minte } = useGetMintedUmi(
      { accountAddress: accountAddress || '' },
      { enabled: !!accountAddress },
  );
  const { data: UmiPrice } = useGetUmiPrice(
      { accountAddress: accountAddress || '' },
      { enabled: !!accountAddress },
  );
  const { data: SndicateGloblAmount } = useGetSndicateGloblAmount(
      { accountAddress: accountAddress || '' },
      { enabled: !!accountAddress },
  );
  const { data: StakePool } = useGetStakePool(
      { accountAddress: accountAddress || '' },
      { enabled: !!accountAddress },
  );
  const umiPrice = UmiPrice?.getUmiPrice ? new BigNumber(UmiPrice.getUmiPrice) : new BigNumber(0);
  const umiPool = UmiPrice?.getPoolPrice ? new BigNumber(UmiPrice.getPoolPrice) : new BigNumber(0);

  const marketValue = umiPrice.multipliedBy(33000000);

  const syndicate = Minte ? Minte?.syndicate : '0';

  return (
    <Paper css={styles.container} className={className}>
      {/* <div css={[styles.row, styles.header]}> */}
      {/*   <Typography variant="h4">{t('myAccount.title')}</Typography> */}

      {/*   <div css={styles.apyWithXvs}> */}
      {/*     <Tooltip css={styles.tooltip} title={t('myAccount.apyWithXvsTooltip')}> */}
      {/*       <Icon css={styles.infoIcon} name="info" /> */}
      {/*     </Tooltip> */}

      {/*     <Typography */}
      {/*       color="text.primary" */}
      {/*       variant="small1" */}
      {/*       component="span" */}
      {/*       css={styles.apyWithXvsLabel} */}
      {/*     > */}
      {/*       {t('myAccount.apyWithXvs')} */}
      {/*     </Typography> */}

      {/*     <Toggle css={styles.toggle} value={isXvsEnabled} onChange={handleXvsToggleChange} /> */}
      {/*   </div> */}
      {/* </div> */}

      <div css={styles.netApyContainer}>
        <div css={styles.netApy}>
          <Typography component="span" variant="small2" css={styles.netApyLabel}>
            {t('myAccount.netApy')}
          </Typography>

          { syndicate === '1' && (
              <Tooltip css={styles.tooltip} style={{ width: '70%' }} title={t('myAccount.netApyTooltip')}>
                <img src={crown} alt="" style={{ width: '10%', marginBottom: '4px' }} className={className} />
              </Tooltip>
          )}

          { syndicate === '2' && (
              <Tooltip css={styles.tooltip} style={{ width: '70%' }} title={t('myAccount.netApyTooltip')}>
                <img src={purple_crown} alt="" style={{ width: '10%', marginBottom: '4px' }} className={className} />
              </Tooltip>
          )}
          { syndicate === '0' && (
              <Tooltip css={styles.tooltip} style={{ width: '70%' }} title={t('myAccount.netApyTooltip')}>
                {/* <img src={purple_crown} alt="" style={{ width: '10%', marginBottom: '4px' }} className={className} /> */}
                <Icon css={styles.infoIcon} name="info" />
              </Tooltip>
          )}

        </div>

        <Typography
          variant="h1"
          color={
            netApyPercentage !== undefined && netApyPercentage >= 0
              ? 'interactive.success'
              : 'interactive.error'
          }
          component="span"
        >
          {(umiPrice.dividedBy(new BigNumber(10).pow(18))).toFixed(2)} USDT
        </Typography>
      </div>

      <ul css={styles.list}>
        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.dailyEarnings')}
          </Typography>

          ${(marketValue.dividedBy(new BigNumber(10).pow(18))).toFixed(2)}
        </Typography>

        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.supplyBalance')}
          </Typography>
          {usernumber?.usercount}
        </Typography>

        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.borrowBalance')}
          </Typography>

          ${(umiPool.dividedBy(new BigNumber(10).pow(18))).toFixed(2)}
        </Typography>
        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.pool')}
          </Typography>
          {StakePool ? (new BigNumber(StakePool.Amount).dividedBy(new BigNumber(10).pow(18))).toFixed(2) : 0}
        </Typography>
        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.Syndicatepool')}
          </Typography>
          {SndicateGloblAmount ? (new BigNumber(SndicateGloblAmount.Amount).dividedBy(new BigNumber(10).pow(18))).toFixed(2) : 0}
        </Typography>
      </ul>

      {/* <BorrowLimitUsedAccountHealth */}
      {/*   css={styles.progressBar} */}
      {/*   borrowBalanceCents={borrowBalanceCents} */}
      {/*   safeBorrowLimitPercentage={safeBorrowLimitPercentage} */}
      {/*   borrowLimitCents={borrowLimitCents} */}
      {/* /> */}

      {/* <div css={styles.bottom}> */}
      {/*   <Icon name="shield" css={styles.shieldIcon} /> */}

      {/*   <Typography component="span" variant="small2" css={styles.inlineLabel}> */}
      {/*     {t('myAccount.safeLimit')} */}
      {/*   </Typography> */}

      {/*   <Typography component="span" variant="small1" color="text.primary" css={styles.safeLimit}> */}
      {/*     {readableSafeBorrowLimit} */}
      {/*   </Typography> */}

      {/*   <Tooltip */}
      {/*     css={styles.tooltip} */}
      {/*     title={t('myAccount.safeLimitTooltip', { safeBorrowLimitPercentage })} */}
      {/*   > */}
      {/*     <Icon css={styles.infoIcon} name="info" /> */}
      {/*   </Tooltip> */}
      {/* </div> */}
    </Paper>
  );
};

export default MyAccountUi;
