import BigNumber from 'bignumber.js';
import { checkForVaiControllerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core';

import { UmiVault } from 'types/contracts';
import { TOKENS } from 'constants/tokens';

export interface MintUmiInput {
  umiVaultContract: UmiVault;
  fromAccountAddress: string;
  tokenA: string;
  tokenB: string;
    InviterReward?: string;
  amountWei: BigNumber;
  umiamountWei: BigNumber;
  days: number;
}

export type MintUmiOutput = TransactionReceipt;

const mintUmi = async ({
                         umiVaultContract,
                           tokenA,
                           tokenB,
                           InviterReward = '',
  fromAccountAddress,
  amountWei,
                         umiamountWei,
                         days,
}: MintUmiInput): Promise<MintUmiOutput> => {
    console.log(tokenA);
    console.log(tokenB);
    console.log(InviterReward);
    console.log(fromAccountAddress);
    console.log(amountWei);
    console.log(umiamountWei);
    console.log(days);
  let resp;
  if (TOKENS.bnb.address === tokenA) {
     resp = await umiVaultContract.methods
        .deposit(tokenA, tokenB, amountWei.toFixed(), umiamountWei.toFixed(), InviterReward, days)
        .send({ from: fromAccountAddress, value: amountWei.toFixed() });
  } else {
     resp = await umiVaultContract.methods
        .deposit(tokenA, tokenB, amountWei.toFixed(), umiamountWei.toFixed(), InviterReward, days)
        .send({ from: fromAccountAddress });
  }
  return checkForVaiControllerTransactionError(resp);
};

export default mintUmi;
