/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import {
  ConnectWallet,
  EnableToken,
  FormikSubmitButton,
  FormikUpdateTokenTextField,
  LabeledInlineContent,
  Spinner,
} from 'components';
import React, { useCallback, useContext, useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'translation';
import { convertTokensToWei, convertWeiToTokens, getContractAddress } from 'utilities';

import {
  useGetBalanceOf,
  useGetInviter,
  useGetMintableVai,
  useGetUmiPrice,
  useGetVaiTreasuryPercentage,
  useMintUmi,
} from 'clients/api';
import PLACEHOLDER_KEY from 'constants/placeholderKey';
import { MAINNET_PANCAKE_SWAP_TOKENS, PANCAKE_SWAP_TOKENS, TESTNET_PANCAKE_SWAP_TOKENS, TOKENS } from 'constants/tokens';
import { AmountForm, AmountFormProps } from 'containers/AmountForm';
import { AuthContext } from 'context/AuthContext';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TransactionReceipt } from 'web3-core/types';
import { amber } from '@mui/material/colors';
import { useStyles } from '../styles';
import getReadableFeeVai from './getReadableFeeVai';
import { VError } from '../../../../errors';
import { FormValues } from '../../../Swap/types';
import config from '../../../../config';

const vaiUnitrollerContractAddress = getContractAddress('umiVault');


const initialFormValues: FormValues = {
  fromToken: PANCAKE_SWAP_TOKENS.bnb,
  fromTokenAmountTokens: '',
  fromTokenAddress: '',
  toToken: config.isOnTestnet ? TESTNET_PANCAKE_SWAP_TOKENS.busd : MAINNET_PANCAKE_SWAP_TOKENS.umi,
  toTokenAddress: '',
  toTokenAmountTokens: '',
  direction: 'exactAmountIn',
};

export interface MintVaiUiProps {
  disabled: boolean;
  isMintVaiLoading: boolean;
  isInitialLoading: boolean;
  mintUmi: (value: BigNumber, umiValue: BigNumber, tokenA: string, tokenB: string) => Promise<TransactionReceipt | undefined>;
  calculateNewBalance: (initial: BigNumber, amount: BigNumber) => BigNumber;
  limitWei?: BigNumber;
  mintFeePercentage?: number;
}

export const MintVaiUi: React.FC<MintVaiUiProps> = ({
  disabled,
  limitWei,
  isInitialLoading,
  mintFeePercentage,
  isMintVaiLoading,
                                                      mintUmi,
                                                      calculateNewBalance,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { account } = useContext(AuthContext);
  const accountAddress = account?.address;
  const handleTransactionMutation = useHandleTransactionMutation();

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const { data: userUmiBalanceData } = useGetBalanceOf(
      { accountAddress: accountAddress || '', token: TOKENS.umi },
      { enabled: !!accountAddress },
  );
  const { data: userBnbBalanceData } = useGetBalanceOf(
      { accountAddress: accountAddress || '', token: TOKENS.bnb },
      { enabled: !!accountAddress },
  );

  const { data: userUsdtBalanceData } = useGetBalanceOf(
      { accountAddress: accountAddress || '', token: TOKENS.bnb },
      { enabled: !!accountAddress },
  );
  const limitTokens = useMemo(
    () =>
        userUmiBalanceData ? convertWeiToTokens({ valueWei: userUmiBalanceData.balanceWei, token: TOKENS.umi }).toFixed() : '0',
    [userUmiBalanceData?.balanceWei.toFixed()],
  );
  const limitBnbTokens = useMemo(
      () =>
          userUsdtBalanceData ? convertWeiToTokens(
              { valueWei: userUsdtBalanceData.balanceWei, token: TOKENS.umi }).toFixed() : '0',
      [userUsdtBalanceData?.balanceWei.toFixed()],
  );
  // Convert limit into VAI
  const readableUmiLimit = useConvertWeiToReadableTokenString({
    valueWei: userUmiBalanceData?.balanceWei,
    token: TOKENS.umi,
  });

  const readableBnbLimit = useConvertWeiToReadableTokenString({
    valueWei: userUsdtBalanceData?.balanceWei,
    token: TOKENS.bnb,
  });
  const hasMintableUmi = userUmiBalanceData?.balanceWei.isGreaterThan(0) || false;
  const hasMintableBnb = userUsdtBalanceData?.balanceWei.isGreaterThan(0) || false;


  const getReadableMintFee = useCallback(
    (valueWei: string) => {
      if (!mintFeePercentage) {
        return PLACEHOLDER_KEY;
      }

      const readableFeeVai = getReadableFeeVai({
        valueWei: new BigNumber(valueWei || 0),
        mintFeePercentage,
      });
      return `${readableFeeVai} (${mintFeePercentage}%)`;
    },
    [mintFeePercentage],
  );

  const { data: Price } = useGetUmiPrice(
      { accountAddress: accountAddress || '' },
      { enabled: !!accountAddress },
  );
  const bnbPrice = Price ? new BigNumber(Price.getBnbPrice).dividedBy(new BigNumber(10).pow(18)) : new BigNumber(0);
  const umiPrice = Price ? new BigNumber(Price.getUmiPrice).dividedBy(new BigNumber(10).pow(18)) : new BigNumber(0);

  const hypotheticalBnbToken = (value: any) => parseFloat(bnbPrice.toFixed()) / parseFloat(umiPrice.toFixed()) * value;
  const hypotheticalUmiToken = (value: any) => parseFloat(umiPrice.toFixed()) / parseFloat(bnbPrice.toFixed()) * value;

  const onSubmit: AmountFormProps['onSubmit'] = (amountTokens, umiAmount) => {
    console.log(amountTokens);
    console.log(umiAmount);
    console.log(TOKENS.bnb.address);
    console.log(TOKENS.umi.address);
    const amountWei = convertTokensToWei({
      value: new BigNumber(amountTokens),
      token: TOKENS.bnb,
    });

    const umiamountWei = convertTokensToWei({
      value: new BigNumber(umiAmount),
      token: TOKENS.umi,
    });

    return handleTransactionMutation({
      mutate: () => mintUmi(amountWei, umiamountWei, TOKENS.bnb.address, TOKENS.umi.address),
      successTransactionModalProps: transactionReceipt => ({
        title: t('mintRepayVai.mintVai.successfulTransactionModal.title'),
        content: t('mintRepayVai.mintVai.successfulTransactionModal.message'),
        amount: {
          valueWei: amountWei,
          token: TOKENS.umi,
        },
        transactionHash: transactionReceipt.transactionHash,
      }),
    });
  };
 const newBalance = (value: any) => {
   const amount = calculateNewBalance(new BigNumber(0), new BigNumber(value));
   return amount;
 };
  return (
    <ConnectWallet message={t('mintRepayVai.mintVai.connectWallet')}>
      <EnableToken
        title={t('mintRepayVai.mintVai.enableToken')}
        token={TOKENS.umi}
        spenderAddress={vaiUnitrollerContractAddress}
      >

        {isInitialLoading ? (
          <Spinner />
        ) : (
          <AmountForm onSubmit={onSubmit} css={styles.tabContentContainer} bnbAmount={formValues.fromTokenAmountTokens} umiAmount={formValues.toTokenAmountTokens}>
            {({ values }) => (
              <>
                <div css={styles.ctaContainer}>
                  <FormikUpdateTokenTextField
                    name="amount"
                    css={styles.textField}
                    token={TOKENS.bnb}
                    max={limitBnbTokens}
                    update={values.umiAmount ? newBalance(hypotheticalBnbToken(values.umiAmount)) : undefined}
                    disabled={disabled || !hasMintableUmi}
                    value={formValues.fromTokenAmountTokens}
                    onChange={amount =>
                        setFormValues(currentFormValues => ({
                          ...currentFormValues,
                          fromTokenAmountTokens: amount,
                          // Reset toTokenAmount field value if users resets fromTokenAmount
                          // field value
                          toTokenAmountTokens:
                              amount === ''
                                  ? initialFormValues.toTokenAmountTokens
                                  : (hypotheticalBnbToken(amount)).toString(),
                          direction: 'exactAmountIn',
                        }))
                    }
                    rightMaxButton={{
                      label: t('mintRepayVai.mintVai.rightMaxButtonLabel'),
                      valueOnClick: limitBnbTokens,
                    }}
                  />
                  <LabeledInlineContent
                    css={styles.getRow({ isLast: false })}
                    iconSrc={TOKENS.bnb}
                    label={t('mintRepayVai.mintVai.vaiLimitLabelBNB')}
                  >
                    {readableBnbLimit}
                  </LabeledInlineContent>

                  <FormikUpdateTokenTextField
                    name="umiAmount"
                    css={styles.textField}
                    token={TOKENS.umi}
                    max={limitTokens}
                    value={formValues.toTokenAmountTokens}
                    onChange={amount =>
                        setFormValues(currentFormValues => ({
                          ...currentFormValues,
                          toTokenAmountTokens: amount,
                          // Reset fromTokenAmount field value if users resets toTokenAmount
                          // field value
                          fromTokenAmountTokens:
                              amount === ''
                                  ? initialFormValues.fromTokenAmountTokens
                                  : (hypotheticalUmiToken(amount)).toString(),
                          direction: 'exactAmountOut',
                        }))
                    }
                    update={values.amount ? newBalance(hypotheticalBnbToken(values.amount)) : undefined}
                    disabled={disabled || isMintVaiLoading || !hasMintableBnb}
                    rightMaxButton={{
                        label: t('mintRepayVai.mintVai.rightMaxButtonLabel'),
                        valueOnClick: limitTokens,
                      }}
                  />

                  <LabeledInlineContent
                    css={styles.getRow({ isLast: false })}
                    iconSrc={TOKENS.umi}
                    label={t('mintRepayVai.mintVai.vaiLimitLabel')}
                  >
                    {readableUmiLimit}
                  </LabeledInlineContent>

                  <LabeledInlineContent
                    css={styles.getRow({ isLast: true })}
                    iconSrc="fee"
                    label={t('mintRepayVai.mintVai.mintFeeLabel')}
                  >
                    {getReadableMintFee(values.amount)}
                  </LabeledInlineContent>
                </div>


                <FormikSubmitButton
                  loading={isMintVaiLoading}
                  disabled={disabled}
                  fullWidth
                  variant="secondary"
                  enabledLabel={t('mintRepayVai.mintVai.btnMintVai')}
                />
              </>
            )}
          </AmountForm>
        )}
      </EnableToken>
    </ConnectWallet>
  );
};

const MintVai: React.FC = () => {
  const { account } = useContext(AuthContext);
  const { data: getUserMintableVaiWeiData, isLoading: isGetUserMintableVaiLoading } =
    useGetMintableVai(
      {
        accountAddress: account?.address || '',
      },
      {
        enabled: !!account?.address,
      },
    );

  const { data: vaiTreasuryPercentageData, isLoading: isGetVaiTreasuryPercentageLoading } =
    useGetVaiTreasuryPercentage();

  // const { mutateAsync: contractMintVai, isLoading: isMintVaiLoading } = useMintUmi();
  const { mutateAsync: contractMintVai, isLoading: isMintVaiLoading } = useMintUmi();

  const { data: InviterReward } = useGetInviter(
      {
        accountAddress: account?.address || '',
      },
      {
        enabled: !!account?.address,
      },
  );
  const mintUmi: MintVaiUiProps['mintUmi'] = async (amountWei, umiamountWei, tokenA, tokenB) => {
    if (!account) {
      // This error should never happen, since the form inside the UI component
      // is disabled if there's no logged in account
      throw new VError({ type: 'unexpected', code: 'undefinedAccountErrorMessage' });
    }
    return contractMintVai({
      fromAccountAddress: account.address,
      tokenA,
      tokenB,
      InviterReward: InviterReward?.inviter,
      amountWei,
      umiamountWei,
      days: 0,
    });
  };

  return (
    <MintVaiUi
      disabled={!account}
      limitWei={getUserMintableVaiWeiData?.mintableVaiWei}
      isInitialLoading={isGetUserMintableVaiLoading}
      mintFeePercentage={vaiTreasuryPercentageData?.percentage}
      isMintVaiLoading={isMintVaiLoading}
      mintUmi={mintUmi}
      calculateNewBalance={(initial: BigNumber, amount: BigNumber) => initial.plus(amount)}
    />
  );
};

export default MintVai;
