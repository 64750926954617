import { useMemo } from 'react';
import { Vault } from 'types';

import useGetUmiVault from './useGetUmiVault';
import useGetSmcVault from './useGetSmcVault';
import useGetCumiVault from './useGetCumiVault';
import useGetUmiUsdtVault from './useGetUmiUsdtVault';
import useGetCumiUsdtVault from './useGetCumiUsdtVault';
import useGetSmcUsdtVault from './useGetSmcUsdtVault';

export interface UseGetVaultsOutput {
  isLoading: boolean;
  data: Vault[];
}

const useGetVaults = ({ accountAddress }: { accountAddress?: string }): UseGetVaultsOutput => {
  // const { data: vestingVaults, isLoading: isGetVestingVaultsLoading } = useGetVestingVaults({
  //   accountAddress,
  // });

  const { data: vaiVault, isLoading: isVaiVaultLoading } = useGetUmiVault({
    accountAddress,
  });

  const { data: umiUsdt, isLoading: isUmiUsdtVaultLoading } = useGetUmiUsdtVault({
    accountAddress,
  });

  const { data: smcUsdt, isLoading: isVaiSmcUsdtVaultLoading } = useGetSmcUsdtVault({
    accountAddress,
  });
  const { data: vrtVault, isLoading: isVrtVaultLoading } = useGetSmcVault({
    accountAddress,
  });

  console.log(umiUsdt);

  const data: Vault[] = useMemo(() => {
    const allVaults = [];

    if (vaiVault && umiUsdt) {
      allVaults.push(vaiVault);
      allVaults.push(umiUsdt);
    }

    if (vrtVault && smcUsdt) {
      allVaults.push(vrtVault);
      allVaults.push(smcUsdt);
    }

    return allVaults;
  }, [JSON.stringify(vaiVault), JSON.stringify(vrtVault), JSON.stringify(umiUsdt), JSON.stringify(smcUsdt)]);

  const isLoading = isVaiVaultLoading || isVrtVaultLoading || isVaiSmcUsdtVaultLoading || isUmiUsdtVaultLoading;

  return {
    data,
    isLoading,
  };
};

export default useGetVaults;
